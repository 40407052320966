import React, { Fragment, useEffect, useState } from "react";
import { MTableToolbar } from 'material-table';
import * as actions from "../../store/actions";
import { connect } from "react-redux";
import { Button, Checkbox, Grid, Typography } from "@material-ui/core";

import ModalUserAppsConfig from "../Configuraciones/ModalUserAppsConfig/ModalUserAppsConfig";
import Table from "../UI/Table/Table";
import ModalFormUpdateUser from "../Configuraciones/ModalFormUpdateUser/ModalFormUpdateUser";
import classes from "./ListUsersDataTable.css";
import { DeleteOutline, EditOutlined, PersonAddOutlined, PeopleOutlined, Check, Close } from "@material-ui/icons";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@material-ui/lab";
import { CardPerfilesClientes } from "../Permisos";

const ListUsersDataTable = (props) => {
  const { onLoadUsuarios, onLoadEmpresas, onLoadPerfiles, empresas,
    usuarios, loadingEmpresas, loadingUsuarios, loadingPerfiles, cliente, loadingCliente, onLoadCliente } = props;

  const [clienteId] = useState(Number(sessionStorage.getItem("clienteId")));

  let emptyUser = {
    id: undefined,
    nombre: undefined,
    apellido: undefined,
    email: undefined,
    telefono: undefined,
    baja: false,
    accesoWeb: false,
    accesoApp: false,
    comentarios: undefined,
    empresa: undefined,
    empresas: [],
    indicadores: [],
    perfilid: '',
    perfil: undefined,
    imagen: undefined,
    password: undefined
  };

  const [user, setUser] = useState({ ...emptyUser });
  const [showUpdateUser, setShowUpdateUser] = useState(false);
  const [showFABMenu, setShowFABMenu] = useState(false);

  const cellStyles = {
    padding: 0,
    height: 42,
    width: 50,
    fontSize: '12px',
    paddingBottom: '0px',
    paddingTop: '0px',
    paddingLeft: '5px',
    paddingRight: '5px'
  };

  const headerStyles = {
    fontSize: 'small',
    paddingTop: '0px',
    paddingBottom: '0px',
    backgroundColor: 'white',
    paddingLeft: '5px',
    paddingRight: '5px'
  };

  const getColumns = () => {
    return [
      {
        title: "Nombre",
        field: "nombre",
        validate: (rowData) => {
          return rowData.nombre === "" ? "*Campo requerido" : "";
        },
        headerStyle: headerStyles,
        cellStyle: cellStyles
      },
      {
        title: "Apellido",
        field: "apellido",
        validate: (rowData) => {
          return rowData.nombre === "" ? "*Campo requerido" : "";
        },
        headerStyle: headerStyles,
        cellStyle: cellStyles
      },
      {
        title: "Email",
        field: "email",
        validate: (rowData) => {
          return rowData.email === "" ? "*Campo requerido" : "";
        },
        headerStyle: headerStyles,
        cellStyle: cellStyles
      },
      {
        title: "Perfil",
        field: "perfilid",
        render: (rowData) => {
          const perfilNombre = rowData.perfil && !rowData.perfil.demo && !rowData.perfil.gauss
            ? rowData.perfil.nombre : '';

          return (
            <Typography>
              {perfilNombre}
            </Typography>
          )
        },
        headerStyle: headerStyles,
        cellStyle: cellStyles
      },
      {
        title: "Activo",
        field: "baja",
        type: "boolean",
        render: (rowData) => {
          return (
            <Checkbox color="primary" size="small" checked={!rowData.baja} disabled={true} />
          )
        },
        headerStyle: headerStyles,
        cellStyle: cellStyles
      }
    ]
  }

  useEffect(() => {
    onLoadCliente(clienteId);
  }, [clienteId, onLoadCliente]);

  useEffect(() => {
    onLoadUsuarios();
  }, [onLoadUsuarios]);

  useEffect(() => {
    onLoadEmpresas(clienteId);
  }, [onLoadEmpresas, clienteId]);

  useEffect(() => {
    onLoadPerfiles();
  }, [onLoadPerfiles]);

  const onDeleteUsuario = (oldData, resolve) => {
    resolve();
  }

  const data = () => {
    return usuarios.map((usuario) => ({
      id: usuario.id,
      nombre: usuario.nombre,
      apellido: usuario.apellido,
      email: usuario.email,
      telefono: usuario.telefono,
      baja: usuario.baja,
      accesoWeb: usuario.accesoWeb ? usuario.accesoWeb : false,
      accesoApp: usuario.accesoApp ? usuario.accesoApp : false,
      comentarios: usuario.comentarios,
      empresa: usuario.empresa,
      empresas: usuario.empresas,
      indicadores: usuario.indicadores,
      perfilId: usuario.perfilId,
      perfil: usuario.perfil,
      imagen: usuario.imagen,
      password: usuario.password
    }));
  };

  const actionsFab = [
    {
      icon: <PeopleOutlined />,
      name: 'Admin. Perfiles',
      action: () => {
        setShowFABMenu(false);
        props.onShowPerfilModal && props.onShowPerfilModal("Admin. Perfiles", <CardPerfilesClientes />);
      }
    },
    {
      icon: <PersonAddOutlined />,
      name: 'Crear usuario',
      action: () => {
        setUser({ ...emptyUser });
        setShowFABMenu(false);
        setShowUpdateUser(true);
      }
    },
  ];

  const inLoading = loadingCliente || loadingUsuarios || loadingEmpresas || loadingPerfiles;
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} container className={`${classes.ListUserDataTableContainer} MuiPaper-elevation2 MuiPaper-rounded`}>
        <Grid item xs={12} style={{ paddingInline: '15%', marginTop: '20px' }}>
          <Table style={{ boxShadow: 'none' }}
            title=""
            columns={getColumns()}
            data={data()}
            loading={inLoading}
            components={{
              Toolbar: props => (
                <Grid container>
                  <Grid item xs={12} lg={9}>
                    <MTableToolbar {...props} style={{ padding: '0px' }} />
                  </Grid>
                  <Grid item xs={12} lg={3} container direction="row">
                    <Grid item xs={6} style={{ textAlign: 'center' }}>
                      <span>Licencias App</span><br />
                      <span>{cliente !== null ? `${cliente.licenciasAppUtilizadas}/${cliente.licenciasApp}` : '0/0'}</span>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'center' }}>
                      <span>Licencias Web</span><br />
                      <span>{cliente !== null ? `${cliente.licenciasWebUtilizadas}/${cliente.licenciasWeb}` : '0/0'}</span>
                    </Grid>
                  </Grid>
                </Grid>
              ),
              Action: (props) => {
                var buttonKey = props.action && props.action.icon ? props.action.icon : props.action(props.data).tooltip;

                if (buttonKey === "delete") {
                  return (
                    <Button
                      onClick={(event) => props.action(props.data).onClick(event, props.data)}
                      variant="outlined"
                      size="small"
                      color="primary"
                      title="Eliminar usuario"
                      className={classes.actionButton}
                    >
                      <DeleteOutline style={{ color: '#626262' }} />
                    </Button>
                  );
                } else {
                  buttonKey = props.action.tooltip;

                  const icon = buttonKey === "Guardar cambios" || buttonKey === "Confirmar"
                    ? <Check style={{ color: '#626262' }} />
                    : buttonKey === "Cancelar"
                      ? <Close style={{ color: '#626262' }} />
                      : props.action.icon && props.action.icon();
                  return (
                    <Button
                      onClick={(event) => props.action.onClick(event, props.data)}
                      variant="outlined"
                      size="small"
                      color="primary"
                      title={props.action.tooltip}
                      className={classes.actionButton}
                    >
                      {icon}
                    </Button>
                  );
                }
              }
            }}
            editable={{
              deleteTooltip: rowData => "delete",
              onRowDelete: (oldData) =>
                new Promise((resolve) => {
                  onDeleteUsuario(oldData, resolve);
                }),
            }}
            actions={[
              {
                icon: () => <EditOutlined style={{ color: "rgba(0, 0, 0, 0.54)" }} />,
                iconProps: { style: { color: "rgba(0, 0, 0, 0.54)" } },
                tooltip: "Modificar usuario",
                onClick: (event, rowData) => {
                  setUser(rowData);
                  setShowUpdateUser(true);
                },
              },
            ]}
            customOptions={{
              actionsColumnIndex: -1,
              grouping: false,
              draggable: false,
              columnResizable: false,
              tableLayout: "auto",
              search: true,
              searchFieldVariant: "outlined",
              searchFieldStyle: { backgroundColor: '#FFFFFF', marginBottom: '15px' },
              searchFieldAlignment: 'left',
              sorting: true,
            }}
            actionsInFirstColumn
          />
          {props.showAppsConfig && <ModalUserAppsConfig />}
          {showUpdateUser && (
            <ModalFormUpdateUser
              user={user}
              show={showUpdateUser}
              empresas={empresas}
              clienteId={clienteId}
              onClose={() => setShowUpdateUser(false)}
            />
          )}
        </Grid>
      </Grid>

      <Grid item xs={12} style={{ position: 'fixed', bottom: '5%', right: 0, margin: '20px' }}>
        <SpeedDial
          ariaLabel="SpeedDial basic example"
          icon={<SpeedDialIcon className={classes.SpeedDialIconRoot} />}
          onClose={() => setShowFABMenu(false)}
          onOpen={() => setShowFABMenu(true)}
          open={showFABMenu}
          classes={{
            fab: classes.SpeedDialIconRoot
          }}
        >
          {actionsFab.map((actionFab) => (
            <SpeedDialAction
              key={actionFab.name}
              icon={actionFab.icon}
              tooltipTitle={actionFab.name}
              tooltipOpen={showFABMenu}
              onClick={actionFab.action}
              classes={{
                staticTooltipLabel: classes.StaticTooltipLabel
              }}
            />
          ))}
        </SpeedDial>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    loadingUsuarios: state.usuarios.usuariosPorCliente.loading,
    usuarios: state.usuarios.usuariosPorCliente.usuarios,
    showAppsConfig: state.configuraciones.dashboardUserConfig.show,
    cliente: state.clientes.cliente.cliente,
    loadingCliente: state.clientes.cliente.loading,
    clienteId: state.clientes.clientSelected.clienteId,
    clienteIdPg: state.clientes.clientSelected.clienteIdPg,
    perfiles: state.permisos.loadPerfiles.items,
    loadingPerfiles: state.permisos.loadPerfiles.loading,
    loadingEmpresas: state.empresas.empresasDelCliente.loading,
    empresas: state.empresas.empresasDelCliente.empresas
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadCliente: (clienteId) => dispatch(actions.loadCliente(clienteId)),
    onLoadPerfiles: () => dispatch(actions.loadPerfiles()),
    onLoadUsuarios: () => dispatch(actions.loadUsuariosPorCliente()),
    onLoadEmpresas: (clienteId) => dispatch(actions.loadEmpresasDelCliente(clienteId)),
    onShowPerfilModal: (title, body) => dispatch(actions.showGlobalModal(title, body, undefined, false, true, "md")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListUsersDataTable);
